import { createGlobalStyle } from 'styled-components';
import { theme } from './theme';
import { transition } from './transition';

export const GlobalStyles = createGlobalStyle`
    *{
        box-sizing: border-box;
    }

    html {
        margin: 0 !important;
    }

    body {
        background-color: ${theme.colors.fondo};
        font-family: Open Sans, sans-serif;
        font-size: 14px;
        line-height: 26px;
        color: ${theme.colors.corporativoOscuro};
        font-weight: 400;
        overflow-x: hidden!important;
        -webkit-font-smoothing: antialiased;
        margin: 0;
    }

    .h1,.h2,.h3,.h4,.h5,.h6,a,h1,h2,h3,h4,h5,h6 {
        color: ${theme.colors.textoOscuro};
        margin-top: 0;
        margin-bottom: .5rem;
    }

    .h2,.h3,h2,h3 {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 600;
    }

    h1 a,h2 a,h3 a,h4 a,h5 a,h6 a {
        color: ${theme.colors.textoOscuro};
    }

    h1 a:hover,h2 a:hover,h3 a:hover,h4 a:hover,h5 a:hover,h6 a:hover {
        ${transition()}
        color: ${theme.colors.corporativoAcento};
    }

    p {
        margin-top: 0;
        margin-bottom: 0;
        line-height: 16px;
        color: ${theme.colors.textoClaro};
    }

    a,p a {
        text-decoration: none;
        cursor: pointer;
        color: ${theme.colors.corporativoClaro};
    }

    a:hover,p a:hover {
        ${transition()}
        color: ${theme.colors.corporativoAcento};
        text-decoration: none;
    }

    .active {
        color: ${theme.colors.corporativoAcento};
        text-decoration: none;
    }

    img {
        vertical-align: middle;
        border-style: none;
    }

    *:focus {
        outline: none;
    }

    dl, ol, ul {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    .segmented-control {
        border-radius: none !important;
    }

    .segmented-control > label {
        line-height: 30.4px !important;
    }

    .SingleDatePicker .DateInput_input {
        width: 100%;
        height: 32px !important;
        border: 1px solid ${theme.colors.corporativoClaro};
        background: #fff !important;
    }

    .DateRangePickerInput {
        line-height: 14px;
    }

    .DateRangePickerInput, .DateRangePickerInput .DateInput {
        background: transparent !important;
    }

    

    .DateInput_input__focused {
        border-bottom-color: ${theme.colors.corporativoClaro};
    }

    .DateInput_input,
    .DateRangePickerInput {
        background-color: transparent !important;
    }

    .simple-month-picker {
        margin-bottom: 20px;
    }

    .simple-month-picker .selected_date_mp {
        color: ${theme.colors.corporativoAcento} !important;
    }

    .simple-month-picker .selected_date_mp:hover {
        color: white !important;
    }

    .simple-month-picker .col_mp:hover,
    .simple-month-picker .col_mp.active {
        background: ${theme.colors.corporativoAcento} !important;
    }
`;
