import React, { createContext, useContext, useReducer } from 'react';
import Alert from 'react-s-alert';

import { AuthContext } from 'context';
import { api } from 'utils';

export const TutoresContext = createContext();

const initialState = {
  tutores: [],
  isLoading: false,
};

const reducer = (state, action) => {
  let tutores;
  switch (action.type) {
    case "TUTORES.PENDING":
      return {
        ...state,
        tutores: [],
        isLoading: true,
      };
    case "TUTORES.SUCCESS":
      return {
        ...state,
        tutores: action.payload,
        isLoading: false,
      };
    case "TUTORES.ERROR":
      return {
        ...state,
        tutores: [],
        isLoading: false,
      };
    case "TUTOR_NUEVO.PENDING":
      return {
				...state,
				isLoading: true,
			};
		case "TUTOR_NUEVO.SUCCESS":
      return {
				...state,
				tutores: [...state.tutores, action.payload].sort((a, b) => {
          if (a.user.apellidos.toUpperCase() < b.user.apellidos.toUpperCase()) { return -1; }
          if (a.user.apellidos.toUpperCase() > b.user.apellidos.toUpperCase()) { return 1; }
          return 0;
        }),
				isLoading: false,
			};
    case "TUTOR_ACTUALIZAR.PENDING":
      return {
				...state,
				isLoading: true,
			};
		case "TUTOR_ACTUALIZAR.SUCCESS":
      tutores = [...state.tutores];
      tutores[state.tutores.findIndex(tutor => tutor.id == action.payload.id)] = action.payload;
      return {
				...state,
				tutores,
				isLoading: false,
			};
		case "TUTOR_ACTUALIZAR.ERROR":
      return {
        ...state,
				isLoading: false,
      };
		case "TUTOR_NUEVO.ERROR":
      return {
				...state,
				isLoading: false,
      };
		default: 
			return state;
  }
};

export const TutoresProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [auth] = useContext(AuthContext);

  const getTutores = (usuario = "") => {
    dispatch({type: "TUTORES.PENDING"});
    api(auth.token, `/tutores?usuario${usuario}`)
    .then(res => {
      dispatch({ type: "TUTORES.SUCCESS", payload: res.data });
    })
    .catch(e => {
      dispatch({ type: "TUTORES.ERROR" });
      Alert.error("Error obteniendo tutores");
      console.log("Error obteniendo tutores: " + e.response);
    });
  };

  const addTutor = data => {
    dispatch({type: "TUTOR_NUEVO.PENDING"});

    api(auth.token, "/tutores", "put", {
        ...data
      })
      .then(res => {
        dispatch({ type: "TUTOR_NUEVO.SUCCESS", payload: res.data});
      })
      .catch(e => {
        dispatch({ type: "TUTOR_NUEVO.ERROR" });
        Alert.error("Error añadiendo tutor");
        console.log("Error añadiendo tutor: " + e);
      });
  }

  const updateTutor = (id, data) => {
    dispatch({type: "TUTOR_ACTUALIZAR.PENDING"});

    api(auth.token, `/tutores/${id}`, "patch", data)
    .then(res => {
      dispatch({ type: "TUTOR_ACTUALIZAR.SUCCESS", payload: res.data });
    })
    .catch(e => {
      dispatch({ type: "TUTOR_ACTUALIZAR.ERROR" });
      Alert.error("Error actualizando tutor");
      console.log("Error actualizando tutor: " + e);
    });
  };

  return (
    <TutoresContext.Provider value={{state, getTutores, addTutor, updateTutor}}>
      {props.children}
    </TutoresContext.Provider>
  );
};