import React from 'react';
import styled from 'styled-components';
import { NavLink } from "react-router-dom";

import { Box, Text } from 'components';
import { useWindowSize } from 'hooks';
import { theme } from 'utils';

const Link = styled(NavLink)`
	font-size: ${theme.fontSizes[1]}px;
	display: block;
	color: rgba(255, 255, 255, 0.6);
	margin-bottom: 0;

	&.active {
		color: white;
		-webkit-box-shadow: inset 4px 0px 0px 0px ${theme.colors.corporativoAcento};
		-moz-box-shadow: inset 4px 0px 0px 0px ${theme.colors.corporativoAcento};
		box-shadow: inset 4px 0px 0px 0px ${theme.colors.corporativoAcento};
	}

	&:hover {
		color: white;
	}
`;

export const Header = () => {
	let { innerWidth } = useWindowSize();
	
	return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			width="100%"
			px={40}
			py={20}
			backgroundColor="corporativoOscuro"
		>
			<Box
				maxWidth={950}
				width="100%"
				m="0 auto"
				px={innerWidth > 1023 ? 40 : 0}
				variant="flexInLine"
				justifyContent="space-between"
			>
				<Text
					color="white"
					fontWeight="700"
					fontSize={1}
					mb={0}
				>
					Reserva de Libros
				</Text>
				<Link to="/salir">
					Salir
				</Link>
			</Box>
		</Box>
	);
}