import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Loader } from 'components';
import { theme } from 'utils';

const StyledFloatingButton = styled.button`
	cursor: pointer;
	border-radius: 100%;
	width: 56px;
	height: 56px;
	background: ${theme.colors.corporativoAcento};
	border: none;
	appearance: none;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 9
`;

const Icon = styled(FontAwesomeIcon)`
	font-size: 24px;
	color: white;
`;

function FloatingButton(props) {
	let onClick = (e) => {
		if(props.onClick && !props.loading) {
			e.preventDefault();
			props.onClick(e);
		}
	}

	return (
		<StyledFloatingButton {...props} onClick={onClick} >
			{props.loading ? (<Loader />) : <Icon icon={props.icon} />}
		</StyledFloatingButton>
	);
}

FloatingButton.defaultProps = {
	onClick: null,
	loading: false,
}

FloatingButton.propTypes = {
	onClick: PropTypes.func,
	loading: PropTypes.bool,
	icon: PropTypes.object,
};

export {FloatingButton};