import axios from 'axios';

export const api = (apitoken, url, method = "get", data, options) => {
	return axios({
		method,
		url,
		data,
		...options,
		baseURL: process.env.REACT_APP_API,
		headers: {
			apitoken,
		}
	});
}