import React, { Fragment, useContext, useEffect, useState } from 'react';
import Alert from 'react-s-alert';
import { Formik } from 'formik';
import { useParams, useHistory } from "react-router-dom";
import { faReply } from '@fortawesome/free-solid-svg-icons';

import { Box, Button, Text, Input, Select, SelectableCard } from 'components';
import { useWindowSize } from 'hooks';

import { AlumnosContext, CursosContext, AuthContext } from 'context';
import { api } from 'utils';

export const NuevaFacturaScreen = () => {
	let { id } = useParams();
	const { state: { alumno }, getAlumno } = useContext(AlumnosContext);
	const { state: { cursos }, getCursos } = useContext(CursosContext);
	const [ loadingStripe, setLoadingStripe ] = useState(false);
	const [auth] = useContext(AuthContext);
	const [libros, setLibros] = useState([]);
	const { innerWidth } = useWindowSize();
	let history = useHistory();

	useEffect(() => {
		getAlumno(id);
		getCursos();
	}, []);

	const volver = () => {
		history.push('/panel/alumnos');
	}

	let initialValues = () => {
		return {
			alumno_id: id,
			tutor_id: auth.usuario.userable.id,
			libros: [],
		};
	};

	useEffect(() => {
		if(alumno !== null) {
			api(auth.token, `/libros?curso=${alumno.curso_id}&nopaginate=1`)
			.then(res => {
				let filtrados = res.data;
				if (alumno.bolsa !== null) {
					filtrados = res.data.filter(libro => !alumno.bolsa.pedidos.includes(libro.isbn));
				}
				setLibros(filtrados);
			})
			.catch(e => {
				Alert.error("Error obteniendo libros");
			});

		}
	}, [alumno]);

	return (
		<Fragment>
			{innerWidth > 1023 && (
				<Fragment>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						mb={10}
					>
						<Text textStyle="sectionTitle" mb={0} as ="h1">
							Nuevo pedido
						</Text>
						<Button onClick={volver} icon={faReply} variant="text">
							Volver
						</Button>
					</Box>
					<Box variant="divider" />
				</Fragment>
			)}
			{alumno !== null && (
				<Formik
					initialValues={initialValues()}
					onSubmit={async (values, { setSubmitting }) => {
						if (values.libros.length > 0) {
							setLoadingStripe(true);
							setSubmitting(true);

							var articulos = [
								...values.libros.map(libro => {return {isbn: parseInt(libro), tipo: "libro"};})
							];

							api(auth.token, `/facturas/solicitar_pago`, 'post', {
								alumno_id: values.alumno_id,
								tutor_id: values.tutor_id,
								articulos
							})
							.then(async res => {					
								window.location.href = res.data.url
							})
							.catch(e => {
								Alert.error("Error creando pedido");
								setSubmitting(false);
								setLoadingStripe(false);
								history.push('/panel/alumnos/' + id);
							});
						} else {
							Alert.error("Debe seleccionar al menos un libro");
							setSubmitting(false);
							setLoadingStripe(false);
						}
						
					}}
				>
					{({
						handleChange,
						handleSubmit,
						isSubmitting,
					}) => (
						<form onSubmit={handleSubmit} >
							<Box
								variant="flexInLine"
								flexDirection={innerWidth > 768 ? "row" : "column"}
								mb={10}
							>
									<Input label="Alumno" value={`${alumno.user.nombre} ${alumno.user.apellidos}`} width="100%" disabled/>
									<Input label="Tutor" value={`${auth.usuario.nombre} ${auth.usuario.apellidos}`} width="100%" disabled/>
							</Box>
							{cursos.length > 0 && (
								<Fragment>
									<Box mt={40}>
										{libros.length > 0 && (
											<Fragment>
												<Text textStyle="sectionSubTitle">
													{cursos.find(curso => curso.id == alumno.curso_id).nombre}
												</Text>
												{libros.length > 0 && libros.map(libro => (
													<SelectableCard
														key={libro.isbn}
														title={libro.titulo}
														subtitle={libro.observaciones ? [libro.editorial, libro.isbn, libro.observaciones] : [libro.editorial, libro.isbn]}
														side={`${libro.pvp}€`}
														name="libros"
														onChange={handleChange}
														value={libro.isbn}
													/>
												))}
											</Fragment>
										)}
									</Box>
								</Fragment>
							)}
							<Button mt={40} loading={loadingStripe}>Realizar pedido</Button>
						</form>
					)}
				</Formik>
			)}
		</Fragment>
	)
}