import React, { useContext, useEffect } from 'react';

import { AuthContext } from 'context';
import { Box, Text, Loader } from 'components';
import { imageUrl } from 'utils';
import Logo from 'images/logo-cid.png';

export const LogoutScreen = () => {
	const [, , logout] = useContext(AuthContext);

	useEffect(() => {
		logout();
	}, []);

	return (
		<Box
			minHeight="100vh"
			width={1/1}
			p={40}
			bg="corporativoOscuro"
			display="flex"
			justifyContent="center"
			alignItems="center">
				<Box
					width={[
						1/1,
						1/1,
						500,
					]}
					bg="white"
					p={40}
					textAlign="center"
					variant="card"
				>
					<Box
						backgroundImage={imageUrl(Logo)}
						backgroundSize="contain"
						backgroundPosition="center center"
						height={[
							60,
							80,
							100
						]}
						backgroundRepeat="no-repeat"
						mb={20}
					/>
					<Text>Cerrando sesión<Loader/></Text>
				</Box>
			</Box>
	);
}
