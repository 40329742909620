import React, { createContext, useContext, useReducer } from 'react';
import Alert from 'react-s-alert';

import { AuthContext } from 'context';
import { api } from 'utils';

export const CursosContext = createContext();

const initialState = {
  cursos: [],
  isLoading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "CURSOS.PENDING":
      return {
        ...state,
        cursos: [],
        isLoading: true,
      };
    case "CURSOS.SUCCESS":
      return {
        ...state,
        cursos: action.payload,
        isLoading: false,
      };
    case "CURSOS.ERROR":
      return {
        ...state,
        cursos: [],
        isLoading: false,
      };
		default: 
			return state;
  }
};

export const CursosProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [auth] = useContext(AuthContext);

  const getCursos = () => {
    dispatch({type: "CURSOS.PENDING"});

    api(auth.token, `/cursos`)
    .then(res => {
      dispatch({ type: "CURSOS.SUCCESS", payload: res.data });
    })
    .catch(e => {
      dispatch({ type: "CURSOS.ERROR" });
      Alert.error("Error obteniendo cursos");
      console.log("Error obteniendo cursos: " + e.response);
    });
  };

  return (
    <CursosContext.Provider value={{state, getCursos}}>
      {props.children}
    </CursosContext.Provider>
  );
};