import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import WebFont from 'webfontloader';
import { ThemeProvider } from 'styled-components'
import Alert from 'react-s-alert';
import { CookiesProvider } from 'react-cookie';


import { App } from './app/App';
import { GlobalStyles, theme } from 'utils';
import { AuthProvider } from 'context';
import { unregister } from './serviceWorker';

import 'react-dates/initialize';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/flip.css';
import 'react-month-picker-calendar/dist/react-month-picker-input.css';

WebFont.load({
  google: {
    families: ['Open Sans:300,400,400i,500,600,700']
  }
});

ReactDOM.render(
  <BrowserRouter basename="/">
    <ThemeProvider theme={theme}>
      <CookiesProvider>
        <AuthProvider>
            <GlobalStyles />
            <Alert stack={{limit: 5}} />
            <App />
        </AuthProvider>
      </CookiesProvider>
    </ThemeProvider>
  </BrowserRouter>
, document.getElementById('root'));

unregister();
