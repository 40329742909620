import React, { useContext, useEffect } from 'react';
import moment from 'moment';
import { Route, Switch, Redirect } from 'react-router-dom'
import { PrivateRoute, PublicRoute } from 'react-router-with-props';

import { AuthContext } from 'context';
import { LoginScreen, LogoutScreen } from 'auth';
import { PanelScreen } from 'panel';

import 'moment/locale/es';

export const App = () => {
  const [auth] = useContext(AuthContext);
    
  useEffect(() => {
    moment.locale('es');
  }, []);

  return (
    <>
      <Switch>
        <Route path="/" exact>
          <PublicRoute
            authed={auth.isLoggedIn}
            redirectTo="/panel/alumnos"
            component={LoginScreen}
          />
        </Route>
        <Route path="/panel">
          <PrivateRoute
            authed={auth.isLoggedIn}
            redirectTo="/"
            component={PanelScreen}
          />
        </Route>
        <Route path="/salir" exact>
          <PrivateRoute
            authed={auth.isLoggedIn}
            redirectTo="/"
            component={LogoutScreen}
          />
        </Route>
        <Redirect from='*' to='/' />
      </Switch>
    </>
  );
}
