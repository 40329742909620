import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { space } from 'styled-system'
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { Box, Text } from 'components';
import { theme, transition } from 'utils';

const StyledSelectableCard = styled(Box)`
  ${space}
  display: block;
  width: 100%;
  border-bottom: 1px solid ${theme.colors.divider};
  padding: 15px 25px 10px 25px;
  overflow: hidden;
  ${transition()}
`;

const Input = styled.input`
  margin: 0 0.75rem 0 0;
  display: none;
`;

const CheckContainer = styled.label`
  cursor: pointer;
  height: 18px;
  width: 18px;
  background: ${props => props.checked ? theme.colors.corporativoAcento : 'white'};
  border-radius: 5px;
  border: 1.5px solid ${props => props.checked ? theme.colors.corporativoAcento : theme.colors.corporativoClaro};
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${transition()}
`;

const Checkmark = styled(FontAwesomeIcon)`
  opacity: ${props => props.checked ? '1' : '0'};
  font-size: 12px;
  color: white;
  ${transition()}
`;

const InfoBlock = styled.span`
	display: inline-block;
	margin-right: 25px;
	padding-bottom: 5px;

	@media (max-width: 768px) {
		display: block;
		margin-right: 0;
		padding: 5px 15px;
		color: ${theme.colors.textoOscuro};

		&:nth-child(odd) {
			background-color: rgba(0,0,0,0.03);
		}
	}
`;

const SideContainer = styled.div`
  padding: 3px 10px;
  border-radius: 5px;
  color: ${theme.colors.textoOscuro}
  margin-bottom: 0;
  white-space: nowrap;
`;

function SelectableCard(props) {
  const [isChecked, setChecked] = useState(props.checked);

  let handleCheked = event => {
    event.stopPropagation();
    setChecked(event.target.checked);
    props.onChange(event);
  }

  const { title, ...selectableCardProps } = props;

  return (
    <StyledSelectableCard {...selectableCardProps} checked={isChecked} >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          width="auto"
          overflow="hidden"
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            mb={props.children ? 10 : 0}
            
          >
            <CheckContainer
              checked={isChecked}
            >
              <Input
                type="checkbox"
                name={props.name}
                onChange={handleCheked}
                checked={isChecked}
                value={props.value}
              />
              <Checkmark
                checked={isChecked}
                icon={faCheck}
              />
            </CheckContainer>
            <Box>
              <Text color="textoOscuro" fontWeight={700} mb={5}>
                {props.title}
              </Text>
              <Text>
                {Array.isArray(props.subtitle) ? (
                  <Fragment>
                    {props.subtitle.map(subtitle => (
                      <InfoBlock>
                        {subtitle}
                      </InfoBlock>
                    ))}
                  </Fragment>
                ) : (
                  <InfoBlock>
                    {props.subtitle}
                  </InfoBlock>
                )}
              </Text>
            </Box>
          </Box>
          <Text>{props.children}</Text>
        </Box>
        <SideContainer>
          {props.side}
        </SideContainer>
      </Box>
    </StyledSelectableCard>
  );
}

SelectableCard.propTypes = {
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
  side: PropTypes.string,
  ...space.propTypes,
};

SelectableCard.defaultProps = {
  onChange: () => {},
  name: '',
  checked: false,
  mb: 15,
  side: '',
};

export { SelectableCard };