import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { PrivateRoute } from 'react-router-with-props';

import { Dashboard } from 'components';
import { AuthContext } from 'context';
import { ConfirmacionScreen, ErrorScreen } from 'stripe';
import { AlumnosScreen } from 'alumnos';
import { NuevaFacturaScreen } from 'facturas';
import {CursosProvider, AlumnosProvider} from 'context';


export const PanelScreen = () => {
	const [auth] = useContext(AuthContext);

	return (
		<Dashboard>
			<AlumnosProvider>
				<CursosProvider>
					<Switch>
						<Redirect exact from='/panel' to='/panel/alumnos' />
						<Route path="/panel/confirmacion">
							<ConfirmacionScreen/>
						</Route>
						<Route path="/panel/error">
							<ErrorScreen/>
						</Route>
						<Route path="/panel/alumnos">
							<AlumnosScreen/>
						</Route>
						<Route path="/panel/pedir/:id">
							<PrivateRoute
								authed={auth.usuario.venta_habilitada}
								redirectTo="/panel/alumnos"
								component={NuevaFacturaScreen}
							/>
						</Route>
						<Redirect from='*' to='/panel/alumnos' />
					</Switch>
				</CursosProvider>
			</AlumnosProvider>
		</Dashboard>
	)
}