import React from 'react';
import styled from 'styled-components';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

import { Box, Text, Button } from 'components';
import { useWindowSize } from 'hooks';
import { theme, transition } from 'utils';

const StyledAlumnoBlock = styled(Box)`
  display: block;
  width: 100%;
  border-bottom: 1px solid ${theme.colors.divider};
  padding: 15px 25px 10px 25px;
	overflow: hidden;
	${transition()}

	@media (max-width: 768px) {
		padding: 15px 0;
	}
`;

const InfoBlock = styled.span`
	display: inline-block;
	margin-right: 25px;
	padding-bottom: 5px;
	white-space: nowrap;

	@media (max-width: 768px) {
		display: block;
		margin-right: 0;
		padding: 5px 15px;
		color: ${theme.colors.textoOscuro};

		&:nth-child(odd) {
			background-color: rgba(0,0,0,0.03);
		}
	}
`;

export const AlumnoBlock = props => {
	let { innerWidth } = useWindowSize();

	let handleClick = e => {
		e.preventDefault();
		e.stopPropagation();
		props.onClick(props.userable_id);
	}

	return (
		<StyledAlumnoBlock>
			{innerWidth > 768 ? (
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
				>
					<Box>
						<Text color="textoOscuro" fontWeight={700} mb={5}>
							{props.apellidos}, {props.nombre}
						</Text>
						<Text>
							<InfoBlock>
								{props.curso}
							</InfoBlock>
						</Text>
					</Box>
					<Button onClick={handleClick} variant="text" icon={faAngleRight} size="small">
						Pedir
					</Button>
				</Box>
			) : (
				<React.Fragment>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
					>
						<Text color="textoOscuro" fontWeight={700} mb={5}>
						{props.apellidos}, {props.nombre}
						</Text>
						<Button onClick={handleClick} variant="text" icon={faAngleRight} size="small" />
					</Box>
					<Box>
						<Text>
							<InfoBlock>
								{props.curso}
							</InfoBlock>
						</Text>
					</Box>
				</React.Fragment>
			)}
    </StyledAlumnoBlock>
	)
}