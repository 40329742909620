import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { space } from 'styled-system'
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

import { Box, Text } from 'components';
import { theme, transition } from 'utils';

const StyledSelect = styled.div`
    position: relative;
`;

const Field = styled.select`
    border-radius: 5px;
    border: 1px solid ${props => props.error ? theme.colors.error : theme.colors.divider};
    background: #fff;
    width: 100%;
    padding: ${props => props.icon ? '10px 15px 10px 40px' : '10px 15px'};
    -moz-appearance:none;
    -webkit-appearance:none;
    appearance:none;
    ${transition()}
    
    :focus  {
        border: 1px solid ${props => props.error ? theme.colors.error : theme.colors.corporativoAcento};
    }

    ::placeholder, ::-ms-input-placeholder  {
        color: ${props => props.error && theme.colors.error};
    }
`;

const Caret = styled(FontAwesomeIcon)`
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(50%, -50%);
`;

const Icon = styled(FontAwesomeIcon)`
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translate(-50%, -50%);
    color: ${theme.colors.corporativoClaro}
`;

function Select(props) {
    let [value, setValue] = useState(props.value);

    let handleValue = e => {
        e.preventDefault();
        e.stopPropagation();
        setValue(e.target.value);
        props.onChange(e);
    }

    return (
        <Box {...props}>
            {props.label && (
                <Fragment>
                    <Text mb={5} fontSize={13} as="span">{props.label}</Text>
                    {props.required && (
                        <Text mb={5} fontSize={13} as="span" color="red"> *</Text>
                    )}
                </Fragment>
            )}
            <StyledSelect>
                { props.icon && (
                    <Icon fixedWidth icon={props.icon}/>
                )}
                <Field
                    icon={props.icon}
                    onChange={handleValue}
                    name={props.name}
                    value={value}
                    required={props.required}
                    error={props.error}
                >
                    {props.placeholder !== null && value == "" && (
                        <option disabled value="">{props.placeholder}</option>
                    )}
                    {props.options.map( option => (
                        <option key={option.value} value={option.value} disabled={option.disabled === true}>{option.text}</option>
                    ))}
                </Field>
                <Caret icon={faCaretDown} />
            </StyledSelect>
        </Box>
    );
}

Select.propTypes = {
    icon: PropTypes.object,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    error: PropTypes.bool,
    name: PropTypes.string,
    options: PropTypes.array.isRequired,
    value: PropTypes.any,
    ...space.propTypes,
};

Select.defaultProps = {
    icon: null,
    placeholder: null,
    onChange: () => {},
    error: false,
    name: '',
    value: null,
    mb: 10,
};

export { Select };