import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { space } from 'styled-system'
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { theme } from 'utils';

const StyledCheckbox = styled.label`
    ${space}
    display: block;
    width: 100%;
    height: 32px;
    border: 1px solid ${theme.colors.corporativoClaro};
    background: #fff;
    margin: ${props => props.margin};
    position: relative;
`;

const Icon = styled(FontAwesomeIcon)`
    position: absolute;
    top: 9px;
    left: 6px;
    color: inherit;
`;

const Input = styled.input`
    margin: 0 0.75rem 0 0;
    display: none;
`;

const Text = styled.span`
    padding: ${props => props.icon ? '0 0 0 calc(0.75rem + 20px)' : '0 0.75rem'};
    line-height: 32px;
    font-size: 13px;
    color: black
`;

const CheckContainer = styled.div`
    cursor: pointer;
    border-left: 1px solid ${theme.colors.corporativoClaro};
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 32px;
    z-index: 1;
    background: #fff;

    &:hover {
        background-color: ${theme.colors.claro};
    }
`;

const Checkmark = styled(FontAwesomeIcon)`
    position: absolute;
    top: 9px;
    right: 9px;
    display: ${props => props.checked ? 'block' : 'none'};
`

function Checkbox(props) {
    const [isChecked, setChecked] = useState(props.checked);

    let handleCheked = event => {
        event.stopPropagation();
        setChecked(event.target.checked);
        props.onChange({name: event.target.name, value: event.target.checked});
    }

    return (
        <StyledCheckbox
                {...props}
            >
                { props.icon && (
                    <Icon icon={props.icon}/>
                )}
                <Input
                    type="checkbox"
                    name={props.name}
                    onChange={handleCheked}
                    checked={isChecked}
                />
                <Text
                    icon={props.icon}
                >
                    {props.placeholder}
                </Text>
                <CheckContainer>
                    <Checkmark
                        checked={isChecked}
                        icon={faCheck}
                    />
                </CheckContainer>
            </StyledCheckbox>
    );
}

Checkbox.propTypes = {
    placeholder: PropTypes.string.isRequired,
    icon: PropTypes.object,
    onChange: PropTypes.func,
    name: PropTypes.string,
    checked: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.number,
    ]),
    ...space.propTypes,
};

Checkbox.defaultProps = {
    icon: null,
    onChange: () => {},
    name: '',
    checked: false,
    mb: 20,
};

export { Checkbox };