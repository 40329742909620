import React from 'react';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { faReply } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';

import { Box, Button, Text} from 'components';
import { useWindowSize } from 'hooks';
import { theme } from 'utils';

const Icon = styled(FontAwesomeIcon)`
	color: ${theme.colors.success};

	@media (min-width: 1023px) {
		font-size: 120px;
	}
`;

export const ConfirmacionScreen = () => {
	let history = useHistory();
	let { innerWidth } = useWindowSize();

	const volver = () => {
		history.push('/panel/alumnos');
	}

	return (
		<Box
			display="flex"
			justifyContent="space-around"
			alignItems="center"
			height="calc(100vh - 146px)"
		>
			{innerWidth > 1023 && (
				<Icon icon={faCheckCircle} />
			)}
			<Box display="flex" flexDirection="column" justifyContent="center"  alignItems={innerWidth < 1023 ? "center" : "flex-start"}>
				<Text mb={20} as ="h1" textAlign="center">
					{innerWidth < 1023 && (
						<Icon icon={faCheckCircle} />
					)} PEDIDO RECIBIDO
				</Text>
				<Button onClick={volver} icon={faReply}>
					Volver
				</Button>
			</Box>
		</Box>
	)
}