import React, { Fragment, useContext, useEffect, useState } from 'react';
import Alert from 'react-s-alert';
import { useParams, useHistory } from "react-router-dom";
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { Box, Button, Text, Sidebar, FloatingButton } from 'components';
import { FacturaBlock } from 'alumnos';
import { AuthContext } from 'context';
import { api } from 'utils';

export const FacturasBlock = () => {
	const { id } = useParams();
	const [auth] = useContext(AuthContext);
	let history = useHistory();
	const [facturas, setFacturas] = useState(null);

	useEffect(() => {
		api(auth.token, `/facturas/listar`)
			.then(res => {
				setFacturas(res.data);
			})
			.catch(e => {
				Alert.error("Error obteniendo facturas");
				console.log("Error obteniendo facturas: " + e.response);
			});
	}, []);

	return (
		<Box>
			{facturas?.length > 0 ? facturas.map(factura => (
				<FacturaBlock {...factura} key={factura.id} />
			)) : (
				<Text>Sin facturas</Text>
			)}
		</Box>			
	)
}