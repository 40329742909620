import React, { useState, createContext, useEffect } from 'react';
import axios from 'axios';
import Alert from 'react-s-alert';
import { useCookies } from 'react-cookie';

export const AuthContext = createContext();

export const AuthProvider = props => {
  const [cookies, setCookie] = useCookies(["pmariaCidLibros"]);

  const [auth, setAuth] = useState(cookies.pmariaCidLibros || {
    usuario: null,
    token: null,
    isLoggedIn: false,
    isLoggin: false,
  });

  useEffect(() => {
    setCookie("pmariaCidLibros", JSON.stringify(auth), {maxAge: 18000, path: "/"});
  }, [auth, setCookie]);

  const login = code => {
    setAuth({
      usuario: null,
      token: null,
      isLoggedIn: false,
      isLoggin: true,
    });
    axios.post(`${process.env.REACT_APP_API}/login`, {
      code,
      return_url: `${process.env.REACT_APP_URL}/`
    })
    .then(res => {
      console.log(res.data);
      if (res.data.userable_type == "tutor") {
        setAuth({
          usuario: res.data,
          token: res.data.token,
          isLoggedIn: true,
          isLoggin: false,
        });
      } else {
        Alert.error("No tienes permiso para acceder al sistema de compras, revisa el enlace");
        setAuth({
          usuario: null,
          token: null,
          isLoggedIn: false,
          isLoggin: false,
        });
      }
    })
    .catch(error => {
      Alert.error("Error iniciando sesión, pruebe de nuevo");
      console.log(error);
      setAuth({
        usuario: null,
        token: null,
        isLoggedIn: false,
        isLoggin: false,
      });
    });
  }

  const logout = () => {
    setAuth({
      usuario: null,
      token: null,
      isLoggedIn: false,
      isLoggin: false,
    });
  }

  return (
    <AuthContext.Provider value={[auth, login, logout]}>
      {props.children}
    </AuthContext.Provider>
  );
};