import React, { createContext, useContext, useReducer } from 'react';
import Alert from 'react-s-alert';

import { AuthContext } from 'context';
import { api } from 'utils';

export const EditorialesContext = createContext();

const initialState = {
  editoriales: [],
  editorial: null,
  showForm: false,
  isLoading: false,
};

const reducer = (state, action) => {
  let editoriales;
  switch (action.type) {
    case "EDITORIALES.PENDING":
      return {
				...state,
        editoriales: [],
        isLoading: true,
      };
		case "EDITORIALES.SUCCESS":
      return {
				...state,
        editoriales: action.payload,
        isLoading: false,
      };
		case "EDITORIAL_NUEVA.PENDING":
      return {
				...state,
				isLoading: true,
			};
		case "EDITORIAL_NUEVA.SUCCESS":
      return {
				...state,
				editoriales: [...state.editoriales, action.payload].sort((a, b) => {
          if (a.nombre.toUpperCase() < b.nombre.toUpperCase()) { return -1; }
          if (a.nombre.toUpperCase() > b.nombre.toUpperCase()) { return 1; }
          return 0;
        }),
				isLoading: false,
			};
		case "EDITORIAL_NUEVA.ERROR":
      return {
				...state,
				isLoading: false,
      };
    case "EDITORIAL_ACTUALIZAR.PENDING":
      return {
				...state,
				isLoading: true,
			};
		case "EDITORIAL_ACTUALIZAR.SUCCESS":
      editoriales = [...state.editoriales];
      editoriales[state.editoriales.findIndex(editorial => editorial.id == action.payload.id)] = action.payload;
      return {
				...state,
				editoriales,
				isLoading: false,
			};
		case "EDITORIAL_ACTUALIZAR.ERROR":
      return {
        ...state,
				isLoading: false,
      };
    case "EDITORIAL_ELIMINAR.PENDING":
      return {
				...state,
				isLoading: true,
			};
		case "EDITORIAL_ELIMINAR.SUCCESS":
      return {
				...state,
        editoriales: state.editoriales.filter(editorial => editorial.id != action.payload),
				isLoading: false,
			};
		case "EDITORIAL_ELIMINAR.ERROR":
      return {
        ...state,
        isLoading: false,
      };
		default: 
			return state;
  }
};

export const EditorialesProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [auth] = useContext(AuthContext);

  const getEditoriales = () => {
    dispatch({type: "EDITORIALES.PENDING"});

    api(auth.token, `/editoriales`)
    .then(res => {
      dispatch({ type: "EDITORIALES.SUCCESS", payload: res.data });
    })
    .catch(e => {
      dispatch({ type: "EDITORIALES.ERROR" });
      Alert.error("Error obteniendo editoriales");
      console.log("Error obteniendo editoriales: " + e.response);
    });
  };

  const addEditorial = data => {
    dispatch({type: "EDITORIAL_NUEVA.PENDING"});

    api(auth.token, "/editoriales", "put", {
        ...data
      })
      .then(res => {
        dispatch({ type: "EDITORIAL_NUEVA.SUCCESS", payload: res.data });
      })
      .catch(e => {
        dispatch({ type: "EDITORIAL_NUEVA.ERROR" });
        Alert.error("Error al añadir la editorial");
        console.log("Error al añadir la editorial: " + e);
      });
  }

  const updateEditorial = (id, data) => {
    dispatch({type: "EDITORIAL_ACTUALIZAR.PENDING"});

    api(auth.token, `/editoriales/${id}`, "patch", data)
    .then(res => {
      dispatch({ type: "EDITORIAL_ACTUALIZAR.SUCCESS", payload: res.data });
    })
    .catch(e => {
      dispatch({ type: "EDITORIAL_ACTUALIZAR.ERROR" });
      Alert.error("Error actualizando editorial");
      console.log("Error actualizando editorial: " + e);
    });
  };

  const removeEditorial = id => {
    dispatch({type: "EDITORIAL_ELIMINAR.PENDING"});

    api(auth.token, `/editoriales/${id}`, "delete")
    .then(() => {
      dispatch({ type: "EDITORIAL_ELIMINAR.SUCCESS", payload: id });
    })
    .catch(e => {
      dispatch({ type: "EDITORIAL_ELIMINAR.ERROR" });
      if (e.response.status === 409) {
        Alert.error(e.response.data.error);
      } else {
        Alert.error("Error eliminando editorial");
        console.log("Error eliminando editorial: " + e);
      }      
    });
  };

  return (
    <EditorialesContext.Provider value={{state, getEditoriales, addEditorial, updateEditorial, removeEditorial}}>
      {props.children}
    </EditorialesContext.Provider>
  );
};