import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { AuthContext } from 'context';
import { Box, Text, Button } from 'components';
import { imageUrl } from 'utils';
import Logo from 'images/reserva-libros.png';

const loginUrl = `https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?grant_type=authorization_code&client_id=${process.env.REACT_APP_MS_ID}&client_secret=${process.env.REACT_APP_MS_SECRET}&response_type=code&scope=User.Read&login_hint=@purezamariacidpmvalenciao365.educamos.com&redirect_uri=${process.env.REACT_APP_URL}/`;

export const LoginScreen = props => {
	const [auth, login] = useContext(AuthContext);
	let location = useLocation();
	
	
	useEffect(() => {
		const {code} = queryString.parse(location.search);
		if (code && !auth.isLoggedIn) {
			login(code);
		}
	}, []);

	const irLogin = () => {
		window.location = loginUrl;
	}

  return (
		<Box
			minHeight="100vh"
			width={1/1}
			p={40}
			bg="corporativoOscuro"
			display="flex"
			justifyContent="center"
			alignItems="center"
		>
				<Box
					width={[
						1/1,
						1/1,
						500,
					]}
					bg="white"
					p={40}
					textAlign="center"
					variant="card"
				>
					<Box
						backgroundImage={imageUrl(Logo)}
						backgroundSize="contain"
						backgroundPosition="center center"
						height={[
							60,
							80,
							100
						]}
						backgroundRepeat="no-repeat"
						mb={40}
					/>
					<Button loading={auth.isLoggin} onClick={irLogin} width="100%">Iniciar sesión</Button>
				</Box>
			</Box>
  );
};