import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { space, flexbox, buttonStyle, variant } from 'styled-system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Loader } from 'components';
import { theme, transition } from 'utils';

const buttonSizes = variant({
  scale: 'buttonSizes',
  prop: 'size',
});

const StyledButton = styled.button`
	width: ${props => props.width};
	${buttonStyle}
	${buttonSizes}
	${space}
	${flexbox}
	appearance: none;
	cursor: pointer;
	${transition()}
`;

const Icon = styled(FontAwesomeIcon)`
  color: inherit;
  margin-right: ${props => props.children ? '5px' : 0};
`;

function Button(props) {

	let onClick = e => {
		if(props.onClick && !props.loading) {
			e.preventDefault();
			props.onClick(e);
		}
	};

	let content = () => props.icon ? (<Fragment><Icon icon={props.icon} children={props.children} />{props.children}</Fragment>) : props.children;

	return (
		<StyledButton {...props} onClick={onClick} >
			{props.loading ? (<Loader />) : content()}
		</StyledButton>
	);
}

Button.defaultProps = {
	size: 'medium',
	variant: 'primary',
	onClick: null,
	loading: false,
	type: 'submit',
	width: 'auto',
	display: 'block',
	icon: null,
}

Button.propTypes = {
	...space.propTypes,
	...flexbox.propTypes,
	variant: PropTypes.oneOf(Object.keys(theme.buttons)),
	onClick: PropTypes.func,
	loading: PropTypes.bool,
	type: PropTypes.string,
	icon: PropTypes.object,
};

export {Button};