import React, { createContext, useContext, useReducer } from 'react';
import Alert from 'react-s-alert';

import { AuthContext } from 'context';
import { api } from 'utils';

export const AlumnosContext = createContext();

const initialState = {
  alumnos: [],
  alumno: null,
  isLoading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "ALUMNOS.PENDING":
      return {
        ...state,
        alumnos: [],
        isLoading: true,
      };
    case "ALUMNOS.SUCCESS":
      return {
        ...state,
        alumnos: action.payload,
        isLoading: false,
      };
    case "ALUMNOS.ERROR":
      return {
        ...state,
        alumnos: [],
        isLoading: false,
      };
    case "ALUMNO.PENDING":
      return {
        ...state,
        alumno: null,
        isLoading: true,
      };
    case "ALUMNO.SUCCESS":
      return {
        ...state,
        alumno: action.payload,
        isLoading: false,
      };
    case "ALUMNO.ERROR":
      return {
        ...state,
        alumno: null,
        isLoading: false,
      };
		default: 
			return state;
  }
};

export const AlumnosProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [auth] = useContext(AuthContext);

  const getAlumnos = () => {
    dispatch({type: "ALUMNOS.PENDING"});
    api(auth.token, `/alumnos`)
    .then(res => {
      dispatch({ type: "ALUMNOS.SUCCESS", payload: res.data });
    })
    .catch(e => {
      dispatch({ type: "ALUMNOS.ERROR" });
      Alert.error("Error obteniendo alumnos");
    });
  };

  const getAlumno = id => {

    dispatch({type: "ALUMNO.PENDING"});
    api(auth.token, `/alumnos/${id}`)
    .then(res => {
      dispatch({ type: "ALUMNO.SUCCESS", payload: res.data });
    })
    .catch(e => {
      dispatch({ type: "ALUMNO.ERROR" });
      Alert.error("Error obteniendo alumno");
    });
  };

  return (
    <AlumnosContext.Provider value={{state, getAlumnos, getAlumno}}>
      {props.children}
    </AlumnosContext.Provider>
  );
};