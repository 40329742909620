import React, { createContext, useContext, useReducer } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import Alert from 'react-s-alert';

import { AuthContext } from 'context';
import { api } from 'utils';

export const UsuarioContext = createContext();

const initialState = {
  usuario: null,
  isLoadingUsuario: false,
	isUpdatingUsuario: false,
  isAddingMovimiento: false,
  isLoadingMovimientos: false,
  fechas: {
    inicial: moment().startOf('month'),
	  final: moment().endOf('month'),
  },
	movimientos: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "USUARIO.PENDING":
      return {
				...state,
        usuario: null,
        isLoadingUsuario: true,
			};
		case "USUARIO.SUCCESS":
      return {
				...state,
        usuario: action.payload,
        isLoadingUsuario: false,
      };
		case "USUARIO_ACTUALIZAR.PENDING":
      return {
				...state,
				isUpdatingUsuario: true,
			};
		case "USUARIO_ACTUALIZAR.SUCCESS":
      return {
				...state,
				usuario: action.payload,
				isUpdatingUsuario: false,
			};
		case "USUARIO_ACTUALIZAR.ERROR":
      return {
				...state,
				isUpdatingUsuario: false,
      };
    case "MOVIMIENTO_NUEVO.PENDING":
      return {
				...state,
				isAddingMovimiento: true,
			};
		case "MOVIMIENTO_NUEVO.SUCCESS":
      return {
				...state,
				isAddingMovimiento: false,
			};
		case "MOVIMIENTO_NUEVO.ERROR":
      return {
				...state,
				isAddingMovimiento: false,
      };
    case "RESERVA.SUCCESS":
      return {
        ...state,
        usuario: {
          ...state.usuario,
          reserva: action.payload,
        },
				isAddingMovimiento: false,
      };
    case "MOVIMIENTOS_FECHAS":
      return {
        ...state,
        fechas: {
          inicial: action.payload.startDate,
          final: action.payload.endDate,
        },
      };
    case "MOVIMIENTOS.PENDING":
      return {
        ...state,
        isLoadingMovimientos: true,
      };
    case "MOVIMIENTOS.SUCCESS":
      return {
        ...state,
        movimientos: action.payload,
        isLoadingMovimientos: false,
      };
    case "MOVIMIENTOS.PENDING":
      return {
        ...state,
        isLoadingMovimientos: false,
      };
		default: 
			return state;
  }
};

export const UsuarioProvider = withRouter(props => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [auth] = useContext(AuthContext);
  
  const getUsuario = (id, tarjeta = false) => {
    //console.log(tarjeta);
    dispatch({type: "USUARIO.PENDING"});

    api(auth.token, `/usuarios/${tarjeta ? `tarjeta/${id}` : id}`)
    .then(res => {
      dispatch({ type: "USUARIO.SUCCESS", payload: res.data });
    })
    .catch(e => {
      if (e.response.status === 404) {
        Alert.error("El usuario no existe");
        props.history.replace("/panel");
      } else {
        Alert.error("Error obteniendo el usuario");
        console.log(e.response);
      }
    });
  };

  const updateUsuario = (data) => {
    dispatch({type: "USUARIO_ACTUALIZAR.PENDING"});

    api(auth.token, `/usuarios/${state.usuario.id}`, "patch", data)
    .then(res => {
      Alert.success("Usuario actualizado");
      dispatch({ type: "USUARIO_ACTUALIZAR.SUCCESS", payload: res.data });
      getMovimientos();
    })
    .catch(e => {
      dispatch({ type: "USUARIO_ACTUALIZAR.ERROR" });
      if (e.response.status === 409) {
        Alert.error("La tarjeta pertenece a otro usuario");
      } else {
        Alert.error("Error actualizando usuario");
      }
      console.log("Error actualizando usuario: " + e);
    });
  };

  const nuevoMovimiento = (data) => {
    dispatch({type: "MOVIMIENTO_NUEVO.PENDING"})

    if (state.usuario.tipo === 1 && data.tipo === "recarga") {
      api(auth.token, "/movimientos", "put", {
        usuario: state.usuario.id,
        tipo: data.tipo,
        saldo: data.saldo,
        importe: data.importe,
      })
      .then(res => {
        Alert.success("Movimiento añadido");
        dispatch({ type: "MOVIMIENTO_NUEVO.SUCCESS" });
        getMovimientos();
      })
      .catch(e => {
        dispatch({ type: "MOVIMIENTO_NUEVO.ERROR" });
        Alert.error("Error al añadir el movimiento");
        console.log("Error añadiendo movimiento: " + e);
      });
    } else if (state.usuario.tipo !== 1 && data.tipo === "recarga") {
      api(auth.token, "/movimientos", "put", {
        usuario: state.usuario.id,
        tipo: data.tipo,
        mes: data.mes.unix()
      })
      .then(() => {
        Alert.success("Movimiento añadido");
        dispatch({ type: "MOVIMIENTO_NUEVO.SUCCESS" });
        getMovimientos();
      })
      .catch(e => {
        dispatch({ type: "MOVIMIENTO_NUEVO.ERROR" });
        Alert.error("Error al añadir el movimiento");
        console.log("Error añadiendo movimiento: " + e);
      });
    } else if (data.tipo === "menu") {
      api(auth.token, "/movimientos", "put", {
        usuario: state.usuario.id,
        tipo: data.tipo,
        fecha: data.fecha.unix()
      })
      .then(() => {
        Alert.success("Movimiento añadido");
        dispatch({ type: "MOVIMIENTO_NUEVO.SUCCESS" });
        getMovimientos();
      })
      .catch(e => {
        dispatch({ type: "MOVIMIENTO_NUEVO.ERROR" });
        Alert.error("Error al añadir el movimiento");
        console.log("Error actualizando usuario: " + e);
      });
    } else if (data.tipo === "reserva") {
      api(auth.token, `/reservas/${state.usuario.id}`, "patch", {
        tipo: data.reserva
      })
      .then(res => {
        Alert.success("Reserva guardada");
        dispatch({ type: "RESERVA.SUCCESS", payload: res.data });
      })
      .catch(e => {
        dispatch({ type: "MOVIMIENTO_NUEVO.ERROR" });
        Alert.error("Error al realizar la reserva");
        console.log("Error realizando la reserva: " + e);
      });
    }
  };

  const getMovimientos = () => {
    dispatch({type: "MOVIMIENTOS.PENDING"});

    api(auth.token, `/movimientos?usuario=${state.usuario.id}&fecha_inicio=${state.fechas.inicial.unix()}&fecha_fin=${state.fechas.final.unix()}`)
    .then(res => {
      dispatch({ type: "MOVIMIENTOS.SUCCESS", payload: res.data });
    })
    .catch(e => {
      Alert.error("Error obteniendo movimientos");
      console.log(e.response);
    });
  }

  const setMovimientosFechas = fechas => {
    dispatch({ type: "MOVIMIENTOS_FECHAS", payload: fechas});
    getMovimientos();
  }

  return (
    <UsuarioContext.Provider value={{state, getUsuario, updateUsuario, nuevoMovimiento, getMovimientos, setMovimientosFechas}}>
      {props.children}
    </UsuarioContext.Provider>
  );
});