import React, { Fragment, useContext, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import { Box, Text, Loader } from 'components';

import { AlumnoBlock, FacturasBlock } from 'alumnos';
import { AuthContext, AlumnosContext } from 'context';


export const AlumnosScreen = () => {
	const [auth] = useContext(AuthContext);
	let { state, getAlumnos } = useContext(AlumnosContext);
	let history = useHistory();

	useEffect(() => {
		getAlumnos();
	}, []);

	let irAlumno = id => {
		history.push('/panel/pedir/' + id);
	}

	return (
		<Fragment>
			{auth.usuario.venta_habilitada && (
				<Fragment>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						mb={10}
					>
						<Text textStyle="sectionTitle" mb={0} as ="h1">
							Alumnos
						</Text>
					</Box>
					<Box variant="divider" />
					
					{state.alumnos.length > 0 && !state.isLoading ? (
						<Box>
							{state.alumnos.map(alumno => (
								<AlumnoBlock {...alumno} onClick={id => irAlumno(id)} key={alumno.id}/>
							))}
						</Box>
					) : (
						<Box>
							<Text>Cargando alumnos<Loader /></Text>
						</Box>
					)}
					<Box mt={0} mb={40}/>
				</Fragment>
			)}
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				mb={10}
			>
				<Text textStyle="sectionTitle" mb={0} as ="h1">
					Facturas
				</Text>
			</Box>
			<Box variant="divider" />
			<FacturasBlock />
		</Fragment>
	)
}