import React, { createContext, useContext, useReducer } from 'react';
import Alert from 'react-s-alert';

import { AuthContext } from 'context';
import { api } from 'utils';

export const LibrosContext = createContext();

const initialState = {
  libros: [],
  pagination: {
    current: 1,
    prev: null,
    next: null,
  },
  isLoading: false,
  filtros: {
    buscar: "",
    editorial: "",
    curso: "",
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LIBROS.PENDING":
      return {
        ...state,
        libros: [],
        pagination: {
          current: 1,
          prev: null,
          next: null,
        },
        isLoading: true,
      };
    case "LIBROS.SUCCESS":
      return {
        ...state,
        libros: action.payload.data,
        pagination: {
          current: action.payload.current_page,
          next: action.payload.next_page_url ? action.payload.current_page + 1 : null,
          prev: action.payload.prev_page_url ? action.payload.current_page - 1 : null,
        },
        isLoading: false,
      };
    case "LIBROS.ERROR":
      return {
        ...state,
        libros: [],
        pagination: {
          current: 1,
          prev: null,
          next: null,
        },
        isLoading: false,
      };
    case "SET_FILTROS":
      return {
        ...state,
        filtros: action.payload,
      };
    case "LIBRO_NUEVO.PENDING":
      return {
				...state,
				isLoading: true,
			};
		case "LIBRO_NUEVO.SUCCESS":
      return {
				...state,
				isLoading: false,
			};
		case "LIBRO_NUEVO.ERROR":
      return {
				...state,
				isLoading: false,
      };
    case "LIBRO_ACTUALIZAR.PENDING":
      return {
				...state,
				isLoading: true,
			};
		case "LIBRO_ACTUALIZAR.SUCCESS":
      return {
				...state,
				isLoading: false,
			};
		case "LIBRO_ACTUALIZAR.ERROR":
      return {
        ...state,
				isLoading: false,
      };
    case "LIBRO_ELIMINAR.PENDING":
      return {
				...state,
				isLoading: true,
			};
		case "LIBRO_ELIMINAR.SUCCESS":
      return {
				...state,
				isLoading: false,
			};
		case "LIBRO_ELIMINAR.ERROR":
      return {
        ...state,
        isLoading: false,
      };
		default: 
			return state;
  }
};

export const LibrosProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [auth] = useContext(AuthContext);

  const getLibros = (page = state.pagination.current, filtros = false) => {
    if (filtros === false) {
      filtros = state.filtros;
    }

    dispatch({type: "LIBROS.PENDING"});
    api(auth.token, `/libros?page=${page}&editorial=${filtros.editorial}&curso=${filtros.curso}&search=${filtros.buscar}`)
    .then(res => {
      dispatch({ type: "LIBROS.SUCCESS", payload: res.data });
    })
    .catch(e => {
      dispatch({ type: "LIBROS.ERROR" });
      Alert.error("Error obteniendo libros");
      console.log("Error obteniendo libros: " + e.response);
    });
  };

  const saveFiltros = filtros => {
    dispatch({type: "SET_FILTROS", payload: filtros});
  }

  const addLibro = data => {
    dispatch({type: "LIBRO_NUEVO.PENDING"});

    api(auth.token, "/libros", "put", {
        ...data
      })
      .then(res => {
        dispatch({ type: "LIBRO_NUEVO.SUCCESS"});
        getLibros();
      })
      .catch(e => {
        dispatch({ type: "LIBRO_NUEVO.ERROR" });
        if (e.response.status === 409) {
          Alert.error(e.response.data.error);
        } else {
          Alert.error("Error añadiendo libro");
          console.log("Error añadiendo libro: " + e);
        } 
      });
  }

  const updateLibro = (isbn, data) => {
    dispatch({type: "LIBRO_ACTUALIZAR.PENDING"});

    api(auth.token, `/libros/${isbn}`, "patch", data)
    .then(res => {
      dispatch({ type: "LIBRO_ACTUALIZAR.SUCCESS"});
      getLibros();
    })
    .catch(e => {
      dispatch({ type: "LIBRO_ACTUALIZAR.ERROR" });
      if (e.response.status === 409) {
        Alert.error(e.response.data.error);
      } else {
        Alert.error("Error actualizando libro");
        console.log("Error actualizando libro: " + e);
      }  
    });
  };

  const removeLibro = isbn => {
    dispatch({type: "LIBRO_ELIMINAR.PENDING"});

    api(auth.token, `/libros/${isbn}`, "delete")
    .then(() => {
      dispatch({ type: "LIBRO_ELIMINAR.SUCCESS"});
      if (state.libros.length == 1 && state.pagination.prev) {
        getLibros(state.pagination.prev);
      } else {
        getLibros();
      }
    })
    .catch(e => {
      dispatch({ type: "LIBRO_ELIMINAR.ERROR" });
      Alert.error("Error eliminando libro");
      console.log("Error eliminando libro: " + e);  
    });
  };

  return (
    <LibrosContext.Provider value={{state, getLibros, saveFiltros, addLibro, updateLibro, removeLibro}}>
      {props.children}
    </LibrosContext.Provider>
  );
};