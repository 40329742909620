import styled from 'styled-components';
import {
	compose,
	space,
	color,
	layout,
	flexbox,
	grid,
	background,
	border,
	position,
	shadow,
	variant,
} from 'styled-system';

const variants = variant({
	variants: {
		card: {
			borderRadius: 10,
			boxShadow: "0px 4px 5px -5px rgba(0,0,0,0.50)",
			background: '#ffffff',
			border: '1px solid #f0f0f0',
		},
		divider: {
			width: "100%",
			height: 1,
			backgroundColor: 'divider',
			mb: 20,
		},
		flexInLine: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			columnGap: "10px",
		},
	}
})

export const Box = styled.div(
	compose(
		space,
		color,
		layout,
		flexbox,
		grid,
		background,
		border,
		position,
		shadow,
		variants,
	)
);


Box.propTypes = {
	...space.propTypes,
	...color.propTypes,
	...layout.propTypes,
	...flexbox.propTypes,
	...grid.propTypes,
	...background.propTypes,
	...border.propTypes,
	...position.propTypes,
	...shadow.PropTypes,
};