import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { space, layout } from 'styled-system'
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Box, Text } from 'components';
import { theme, transition } from 'utils';

const StyledInput = styled.div`
    position: relative;
`;

const Field = styled.input`
    border-radius: 5px;
    border: 1px solid ${props => props.error ? theme.colors.error : theme.colors.divider};
    background: #fff;
    width: 100%;
    padding: ${props => props.icon ? '10px 15px 10px 40px' : '10px 15px'};
    ${transition()}
    
    :focus  {
        border: 1px solid ${props => props.error ? theme.colors.error : theme.colors.corporativoAcento};
    }

    ::placeholder, ::-ms-input-placeholder  {
        color: ${props => props.error && theme.colors.error};
    }
`;

const Icon = styled(FontAwesomeIcon)`
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(-50%, -50%);
    color: inherit;
    font-size: 12px;
`;

function Input(props) {
    let [text, setText] = useState(props.value);

    let handleText = e => {
        e.preventDefault();
        e.stopPropagation();
        setText(e.target.value);
        props.onChange(e);
    }

    return (
        <Box {...props}>
            {props.label && (
                <Fragment>
                    <Text mb={5} fontSize={13} as="span">{props.label}</Text>
                    {props.required && (
                        <Text mb={5} fontSize={13} as="span" color="red"> *</Text>
                    )}
                </Fragment>
            )}
            <StyledInput>
                { props.icon && (
                    <Icon fixedWidth icon={props.icon}/>
                )}
                <Field
                    type={props.type}
                    icon={props.icon}
                    placeholder={props.placeholder}
                    value={props.value != null ? props.value : text}
                    onChange={handleText}
                    name={props.name}
                    step={props.step}
                    min={props.min}
                    required={props.required}
                    error={props.error}
                    disabled={props.disabled}
                />
            </StyledInput>
        </Box>
    );
}

Input.propTypes = {
    type: PropTypes.string,
    icon: PropTypes.object,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    error: PropTypes.bool,
    name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    value: PropTypes.any,
    step: PropTypes.number,
    min: PropTypes.number,
    label: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    ...space.propTypes,
    ...layout.propTypes,
};

Input.defaultProps = {
    type: 'text',
    step: 1,
    icon: null,
    placeholder: '',
    onChange: () => {},
    error: false,
    name: '',
    mb: 10,
    value: null,
    min: null,
    label: null,
    required: false,
    disabled: false,
};

export {Input};