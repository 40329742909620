import styled from 'styled-components';
import {
	compose,
	space,
	color,
	typography,
	border,
	position,
	textStyle,
	layout,
} from 'styled-system';

export const Text = styled.p(
	compose(
		space,
		color,
		typography,
		border,
		position,
		textStyle,
		layout,
	)
);

Text.propTypes = {
	...space.propTypes,
	...color.propTypes,
	...typography.propTypes,
	...border.propTypes,
	...position.propTypes,
	...textStyle.propTypes,
	...layout.propTypes,
};