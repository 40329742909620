import { colorAdjust } from 'utils';

const baseTheme = {
    breakpoints: [
        '576px',
        '768px',
        '992px',
        '1200px',
    ],
    space: [0, 2, 4, 8, 16],
    fontSizes: [14, 16, 18, 24, 32],
    colors: {
        corporativoOscuro: '#4c5361',
        corporativoClaro: '#758096',
        corporativoAcento: '#e24d33',   
        textoClaro: '#727987',
        textoOscuro: '#050511',
        fondo: '#f1f3f5',
        claro: '#eff2f3',
        error: '#f4424b',
        danger: '#940d14',
        divider: '#d8dadc',
        success: '#0a8a39',
    }
};

export const theme = {
    ...baseTheme,
    buttonSizes: {
        small: {
            fontSize: 12,
            padding: '10px',
            '& > svg': {
                fontSize: 10,
            }
        },
        medium: {
            fontSize: baseTheme.fontSizes[0],
            padding: '8px 13px',
            '& > svg': {
                fontSize: 12,
            }
        },
        large: {
            fontSize: baseTheme.fontSizes[2],
            padding: '10px 15px',
            '& > svg': {
                fontSize: baseTheme.fontSizes[1],
            }
        }
    },
    buttons: {
        primary: {
            color: 'white',
            border: 'none',
            backgroundColor: baseTheme.colors.corporativoAcento,
            borderRadius: 5,
            '&:hover': {
                backgroundColor: colorAdjust(baseTheme.colors.corporativoAcento, -20),
            }
        },
        secondary: {
            color: baseTheme.colors.corporativoOscuro,
            border: '1px solid ' + baseTheme.colors.corporativoOscuro,
            backgroundColor: 'transparent',
            borderRadius: 5,
            '&:hover': {
                color: colorAdjust(baseTheme.colors.corporativoOscuro, -20),
                border: '1px solid ' + colorAdjust(baseTheme.colors.corporativoOscuro, -20),
                backgroundColor: baseTheme.colors.divider,
            }
        },
        text: {
            color: baseTheme.colors.textoOscuro,
            borderRadius: 5,
            border: 'none',
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: baseTheme.colors.divider,
            }
        },
        danger: {
            color: baseTheme.colors.textoOscuro,
            borderRadius: 5,
            border: 'none',
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: baseTheme.colors.danger,
                color: '#ffffff',
            }
        }
    },
    textStyles: {
        sectionTitle: {
            fontWeight: 'bold',
            fontSize: baseTheme.fontSizes[2],
            letterSpacing: 1.2,
            marginBottom: 20,
            color: baseTheme.colors.textoOscuro
        },
        sectionSubTitle: {
            fontWeight: 'bold',
            fontSize: baseTheme.fontSizes[0],
            letterSpacing: 1.2,
            marginBottom: 20,
            color: baseTheme.colors.textoOscuro
        },
        sidebarTitle: {
            fontWeight: 'bold',
            fontSize: baseTheme.fontSizes[1],
            letterSpacing: 1.2,
            marginBottom: 0,
            color: baseTheme.colors.textoOscuro,
        },
    },
};