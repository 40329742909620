import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Box } from 'components';
import { Header } from './Header';

const Main = styled.main`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
`;

export const Dashboard = (props) => {
	const [displaySidebar, setSidebarVisibility] = useState(false);
	let SidenavRef = useRef(null);
	let location = useLocation();
	let [prevLocation, setPrevLocation] = useState(location);

	useEffect(() => {
		function handleClickOutside(event) {
			if (SidenavRef.current && !SidenavRef.current.contains(event.target)) {
				setSidebarVisibility(false);
			}
		}
		document.addEventListener("mousedown", handleClickOutside);

		if (prevLocation.pathname !== location.pathname) {
			setSidebarVisibility(false);
			setPrevLocation(location);
		}

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [SidenavRef, location]);

	return (
		<Box>
			<Header toggleSidebar={setSidebarVisibility} displaySidebar={displaySidebar} />
			<Main>
				<Box
					maxWidth={950}
					width="100%"
					m="0 auto"
					p={40}
				>
					{props.children}
				</Box>
			</Main>
		</Box>
	)
};