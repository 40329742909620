import React, { useContext } from 'react';
import Alert from 'react-s-alert';
import moment from 'moment';
import styled from 'styled-components';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

import { Box, Text, Button } from 'components';
import { AuthContext } from 'context';
import { transition, api, downloadFile } from 'utils';

const StyledFacturaBlock = styled(Box)`
  display: block;
  width: 100%;
  padding: 10px 0;
	overflow: hidden;
	${transition()}

	@media (max-width: 768px) {
		padding: 5px 0;
	}
`;

export const FacturaBlock = props => {
	const [auth] = useContext(AuthContext);

	let handleClick = e => {
		e.preventDefault();
		e.stopPropagation();
		api(auth.token, `/facturas/${props.id}`, 'get', null, {
			'responseType': 'arraybuffer'
		})
			.then(res => {
				downloadFile(res.data, `factura-${props.id}.pdf`, 'application/pdf');
			})
			.catch(e => {
				Alert.error("Error descargando factura");
				console.log("Error descargando factura: " + e.response);
			});
		
	}

	return (
		<StyledFacturaBlock>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
			>
				<Box>
					<Text mb={5}>
						Factura {props.id} ({moment(props.fecha).format("DD/MM/YYYY")})
					</Text>
				</Box>
				<Button variant="text" onClick={handleClick} icon={faDownload} size="small" />
			</Box>
    </StyledFacturaBlock>
	)
}