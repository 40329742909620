import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';

const blink = keyframes`
    0% {
        opacity: .2;
    }

    20% {
        opacity: 1;
    }

    100% {
        opacity: .2;
    }
`;

const StyledLoader = styled.span`
    color: inherit;
    font-size: inherit;

    & span {
        color: inherit;
        animation: ${blink} 1.4s infinite;
        animation-fill-mode: both
    }

    & span:nth-child(2) {
        animation-delay: .2s;
    }

    & span:nth-child(3) {
        animation-delay: .4s;
    }
`;

export class Loader extends Component {
    render() {
        return (
            <StyledLoader>
                <span>.</span><span>.</span><span>.</span>
            </StyledLoader>
        );
    }
}
